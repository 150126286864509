import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { Button, Slide, Zoom } from '@mui/material';
import { useState } from 'react';
import { BasicModal } from './BasicModal';


const generateTimeLineItem = (name, summary, uuid) => {

    return (
        <TimelineItem key={name}>
            <TimelineOppositeContent color="text.secondary">
                {name}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent> {summary} </TimelineContent>
        </TimelineItem>
    )
}

export const ExperienceTimeline = ({ experience, uuid, isOpen, handleState }) => {
    const containerRef = React.useRef(null);
    // const [isOpen, setIsOpen] = useState(false);

    return (
        <div style={{ heigth: '100%', width: '100%' }}>
            {isOpen &&

                <BasicModal open={isOpen} title={'Work History'} ctaText={'Close'} handleState={handleState}>


                    <Zoom in={isOpen} container={containerRef.current}>
                        <Timeline key={uuid}>
                            {experience.map(x => {
                                const name = Object.keys(x)[0];
                                const summary = x[name];

                                return generateTimeLineItem(name, summary, uuid);
                            })}
                        </Timeline>
                    </Zoom>
                </BasicModal>
            }
        </div>
    );
}