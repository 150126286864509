import MinimalLayout from '../layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('../views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('../views/pages/authentication/authentication3/Register3')));

import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import { authHeader } from '../providers/auth';
import JobList from '../views/jobs/ListJobs';
import UploadResume from '../views/resumes/UploadResume';
import ResumeList from '../views/resumes/ListResumes';
import PerformAnalysis from '../views/analysis/PerformAnalysis';
import AnalysisList from '../views/analysis/ListAnalysis';
import ForgotPassword from '../views/pages/authentication/auth-forms/ForgotPassword';
import PasswordReset from '../views/pages/authentication/auth-forms/ResetPassword';
import BusinessStats from 'src/views/business/BusinessStats';
import BulkAnalysis from 'src/views/analysis/BulkAnalysis';
// import CandidateFinder from 'src/views/finder/CandidateFinder';
import BulkUploadResume from 'src/views/resumes/BulkResume';
import { ManageBusiness } from 'src/views/business/ManageBusiness';
import { AddPeople } from 'src/views/business/AddPeople';
import { CandidateFinder } from 'src/views/finder/CandidateFinder';
// import { CreateAJob } from '../views/jobs/CreateJobSpec';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('../views/sample-page')));
const CreateAJobSpec = Loadable(lazy(() => import('../views/jobs/CreateJobSpec')));
const CreateBusiness = Loadable(lazy(() => import('../views/business/CreateBusiness')));
const ListBusiness = Loadable(lazy(() => import('../views/business/ListBusinesses')));


export const CombinedRoutes = (isAuthed) => [
  { path: "/", element: isAuthed ? <Navigate to="/dashboard" /> : <Navigate to="/login"/> },
  {
    path: '/',
    element: isAuthed ? <Navigate to="/dashboard" /> : <MinimalLayout />,
    children: [
      {
        path: 'login',
        element: <AuthLogin3 />
      },
      {
        path: 'register',
        element: <AuthRegister3 />
      },
      {
        path: 'customer-activate-account/:id',
        element: <AuthLogin3 />
      },
      {
        path: 'ts&cs',
        element: <SamplePage />
      },
      { path: 'customer-reset-password/:id', element: <PasswordReset /> },
      { path: 'customer-forgot-password', element: <ForgotPassword /> },
      {
        path: '*',
        element: <AuthLogin3 />
      },
    ]
  },
  {
    path: '/',
    element: isAuthed ? <MainLayout /> : <Navigate to="/login" />,
    children: [
      {
        path: 'dashboard',
        element: <DashboardDefault />
      },
      {
        path: 'jobs',
        children: [
          {
            path: 'create-job',
            element: <CreateAJobSpec />
          },
          {
            path: 'list-jobs',
            element: <JobList />
          }
        ]
      },
      {
        path: 'resumes',
        children: [
          {
            path: 'bulk-resumes',
            element: <BulkUploadResume />
          },
          {
            path: 'list-resumes',
            element: <ResumeList />
          }
        ]
      },
      {
        path: 'candidates',
        children: [
          {
            path: 'finder',
            element: <CandidateFinder />
          }
        ]
      },
      {
        path: 'analysis',
        children: [
          {
            path: 'perform-analysis',
            element: <PerformAnalysis />
          },
          {
            path: 'list-analysis',
            element: <AnalysisList />
          },
          {
            path: 'bulk-analysis',
            element: <BulkAnalysis />
          }
        ]
      },
      {
        path: 'business',
        children: [
          {
            path: 'create-business',
            element: <CreateBusiness />
          },
          {
            path: 'list-business',
            element: <ListBusiness />
          },
          {
            path: 'data-business',
            element: < BusinessStats/>
          },
          {
            path: 'manage-business',
            element: < ManageBusiness/>
          },
          {
            path: 'add-people',
            element: < AddPeople/>
          }
        ]
      },
      {
        path: 'ts&cs',
        element: <SamplePage />
      },
      {
        path: '*',
        element: <Navigate to="/dashboard" />
      },
    ]
  },
];