import * as React from 'react';
import MUIDataTable from "mui-datatables";
import { useRequest } from '../../providers';
import { useEffect, useState } from 'react';
import { Typography, Button } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Buffer } from "buffer";
import { ThreeDotMenu } from 'src/ui-component/ThreeDotMenu';
import { Check, DeleteOutline, Star } from '@mui/icons-material';


const ResumeList = () => {

    const [getAllBusinesses, businessData, loadingBusiness, error] = useRequest();
    const [downloadFileFromBlob, blobData, loadingBlob, errorBlob] = useRequest();
    const [removeResume, resumeRemovalData, loadingResumeRemoval, errorResumeRemoval] = useRequest();
    const [page, setPage] = useState(0);
    const [stateData, setStateData] = useState([]);

    useEffect(() => {

        getAllBusinesses({
            method: 'GET',
            url: `/api/resumes/v1/getResumeList`,
        }).then((x) => {
            setStateData(x.data);
        });
    }, []);

    const downloadFile = async (candidateId) => {

        if (!candidateId) {
            alert('Something went wrong. E22');
            return;
        }

        const response = await downloadFileFromBlob({
            method: 'POST',
            url: `/api/resumes/v1/downloadResume`,
            data: {
                candidateId
            }
        });

        if (!response.success) {
            alert('Something went wrong. Please try again later.');
            return;
        }

        const buffer = Buffer.from(response.data);
        const blob = new Blob([buffer]);

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.target = '_self'
        a.style = "display: none";
        a.href = url;
        a.download = `${candidateId}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const removeResumeAction = async (resumeId) => {

        if (!resumeId) {
            alert('Something went wrong. No valid resume selected.');
            return;
        }

        const response = await removeResume({
            method: 'POST',
            url: '/api/resumes/v1/removeResume',
            data: {
                resume: resumeId
            }
        });

        if (!response.success) {
            alert('Something went wrong. Please try again later.');
            return;
        }
    };

    const updateResumeAction = async (resumeId, status) => {
        if (!resumeId) {
            alert('Something went wrong. No valid resume selected.');
            return;
        }

        const response = await removeResume({
            method: 'POST',
            url: '/api/resumes/v1/updateStatus',
            data: {
                resume: resumeId,
                status
            }
        });

        if (!response.success) {
            alert('Something went wrong. Please try again later.');
            return;
        }
    };

    const columns = [
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "candidateName",
            label: "Candidate Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "candidateEmail",
            label: "Email",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "location",
            label: "Location",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "blobStorageUrl",
            label: "Download Resume",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (data, dataIndex, rowIndex) => {
                    return (
                        <Button download onClick={() => downloadFile(stateData[dataIndex.rowIndex].uuid)}>Download</Button>
                    );
                }
            }
        },
        {
            name: "",
            label: "",
            options: {
                customBodyRender: (data, dataIndex, rowIndex) => {
                    console.log(dataIndex)
                    return (
                        <ThreeDotMenu options={[{
                            label: 'Delete',
                            onClick: () => {

                                const newData = stateData;
                                removeResumeAction(stateData[dataIndex.rowIndex].uuid);
                                newData.splice(dataIndex.rowIndex, 1);
                                setStateData([...newData]);

                            },
                            icon: <DeleteOutline />,
                            textColor: 'red'
                        },
                        {
                            label: stateData[dataIndex.rowIndex].status === 'shortlist' ? 'Un-Star' : 'Short List',
                            onClick: () => updateResumeAction(stateData[dataIndex.rowIndex].uuid, stateData[dataIndex.rowIndex].status === 'shortlist' ? 'active' : 'shortlist'),
                            icon: <Star />,
                            textColor: 'orange'
                        },
                        {
                            label: 'Mark as Hired',
                            onClick: () => updateResumeAction(stateData[dataIndex.rowIndex].uuid, 'hired'),
                            icon: <Check />,
                            textColor: 'green'
                        }]} />
                    );
                }
            }
        },
    ];


    const options = {
        filter: true,
        filterType: 'dropdown',
        responsive: 'standard',
        expandableRows: true,
        selectableRows: false,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            return (
                <TableRow>
                    <TableCell colSpan={colSpan}> <h3>CV Text:</h3> {stateData[rowMeta.dataIndex].rawText}</TableCell>
                </TableRow>
            );
        },
    };

    return (
        <div style={{ flexGrow: 1 }}>
            <MUIDataTable
                title={<Typography variant="h3">Resume List</Typography>}
                data={stateData ? stateData : []}
                columns={columns}
                options={options}
            />
        </div>
    );
}

export default ResumeList;