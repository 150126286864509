import MainCard from '../../ui-component/cards/MainCard';
import React, { useState } from 'react'
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { useRequest } from '../../providers';
import { Button } from '@mui/material';
import { useRef } from 'react';
import axios from 'axios';

const BulkUploadResume = () => {

  const filepondRef = useRef(null);

  const [files, setFiles] = useState([]);
  const [loadingRequest, setLoadingRequest] = useState(false);

  const postResumes = async () => {

    setLoadingRequest(true);
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem('token');
    axios.defaults.headers.common['Cache-Control'] = 'no-cache';

    await axios.all(files.map(async item => {
      const file = item.file;
      const formData = new FormData();
      formData.append(file.name, file);
      await axios.post(`${process.env.REACT_APP_PROXY}/api/resumes/v3/uploadResumePDF`, formData);
      await filepondRef.current.processFile(item.id);
    })).then(() => {
      alert('All profiles created sucessfully.');
      setLoadingRequest(false)
    })
  };

  return (
    <MainCard title="Upload Resumes (Bulk allowed)" boxShadow shadow={'0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'}>
      <FilePond
        ref={filepondRef}
        acceptedFileTypes={['application/pdf']}
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={true}
        instantUpload={false}
        allowProcess={false}
        server={{
          process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
            load();
            return true
          }
        }}
        credits={false}
        maxFiles={10}
        name="files"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
      <Button
        fullWidth
        disabled={loadingRequest}
        variant="contained"
        style={{ color: "primary", paddingTop: 12 }}
        onClick={() => postResumes()}
      >
        Upload
      </Button>

    </MainCard>
  )
}

export default BulkUploadResume;