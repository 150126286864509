import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import { jobs } from './jobs';
import { admin } from './admin';
import { business } from './business';
import { resumes } from './resumes';
import { analysis } from './analysis';
import { candidateFinder } from './candidateFinder';
import { getRole } from '../providers';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = () => {
  const role = getRole();

  const renderList = [dashboard];
  
  role === 'superAdmin' && renderList.push(admin);

  (role === 'admin' || role === 'superAdmin') && renderList.push(business);

  renderList.push(candidateFinder, jobs, resumes, analysis);

  return renderList;
};

export default menuItems;