
// assets
import { IconFileSearch } from '@tabler/icons-react';

// ==============================|| JOBS MENU ITEMS ||============================== //

export const candidateFinder = {
    id: 'candidateFinder',
    title: 'Finder Tools',
    type: 'group',
    children: [
        {
            id: 'candidate-finder',
            title: 'Candidate Finder',
            type: 'item',
            url: '/candidates/finder',
            icon: IconFileSearch,
            breadcrumbs: false
        }
    ]
};
