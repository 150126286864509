import MainCard from '../../ui-component/cards/MainCard';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { toast, Bounce } from 'react-toastify';
import React, { useState, useEffect } from 'react'
import { useRequest } from '../../providers';

import {
    Button,
    Grid,
    InputLabel,
    OutlinedInput,
    Select,
    MenuItem,
    TextField,
    IconButton,
    Card,
    CardContent,
    Typography,
    Slide
} from '@mui/material';
import { EmailOutlined, Person, Person2Outlined, PinDrop, PinDropOutlined, PinOutlined, Search, AgricultureOutlined, CatchingPokemonOutlined, CalendarMonthOutlined } from '@mui/icons-material';
import { Box } from '@mui/system';
import { ExperienceTimeline } from 'src/ui-component/ExperienceTimeline';
import { ThreeDotMenu } from 'src/ui-component/ThreeDotMenu';
import { BasicModal } from 'src/ui-component/BasicModal';


export const CandidateFinder = () => {

    const [submitAnalysis, analysisData, loadingAnalysis, analysisError] = useRequest();

    const [searchQuery, setSearchQuery] = useState("");
    const [showExperienceModal, setExperienceModal] = useState(false);
    const [modalData, setModalData] = useState(null);


    const createAnalysis = async () => {

        if (!searchQuery) {

            toast.error('Please enter a prompt.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });
            return;
        }

        const response = await submitAnalysis({
            method: 'POST',
            url: `/api/resumes/v1/search`,
            data: {
                searchTerm: searchQuery
            }
        });

        if (response.success) {
            toast.success(response.data, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });
            return;
        }
    }

    const handleExperienceCard = (experience) => {
        setExperienceModal(true);
        setModalData(JSON.parse(experience))
    }

    return (
        <>
            <MainCard
                title="Search for specific skills."
                boxShadow shadow={'0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'}>
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loadingAnalysis}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
                <TextField
                    fullWidth
                    id="search-bar"
                    className="text"
                    onKeyDown={(e) => {
                        if (e.key == 'Enter') {
                            createAnalysis()
                        }
                    }}
                    onInput={(e) => {
                        setSearchQuery(e.target.value);
                    }}
                    label="Keywords work best"
                    variant="outlined"
                    placeholder="Search..."
                />
            </MainCard>

            <Grid
                container
                spacing={1}
                padding={'40px 0 0 0'}
            >

                {analysisData && analysisData.data && analysisData.data.map(element => (
                    <Grid
                        key={element.uuid}
                        item
                        lg={4}
                        md={6}
                        xs={12}
                    >
                        <Card style={{ outline: '4px solid white', outlineOffset: '-3px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
                            <CardContent style={{ height: '25vh', overflow: 'scroll' }}>
                                <Typography variant='h3' component='div' textAlign={'left'} display={'flex'} alignItems={'center'}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                        {element.candidateName}

                                        <ThreeDotMenu options={[{
                                            label: 'Work History',
                                            onClick: () => handleExperienceCard(element.experience),
                                        },]} />
                                    </div>
                                </Typography>
                                <br />
                                <Typography variant='body1' component='div' textAlign={'left'} display={'flex'} alignItems={'center'} gap={'10px'}>
                                    <EmailOutlined color='primary' /> <br /> {element.candidateEmail || 'Unknown'}
                                </Typography>
                                <Typography variant='body1' component='div' textAlign={'left'} display={'flex'} alignItems={'center'} gap={'10px'}>
                                    <PinDropOutlined color='primary' /> <br /> {element.location || 'Unknown'}
                                </Typography>
                                <Typography variant='body1' component='div' textAlign={'left'} display={'flex'} alignItems={'start'} gap={'10px'}>
                                    <CatchingPokemonOutlined color='primary' /> <br /> {element.skills}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}

                {analysisData && analysisData.data && !analysisData.data.length && (
                    <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                    >
                        <Card style={{ boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
                            <CardContent style={{ height: '25vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                <Typography variant='h2' component='div' textAlign={'center'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                        {'Oops, looks like there were no matches :('}
                                </Typography>
                                <Typography variant='body1' component='div' textAlign={'center'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                        {"Only resume's uploaded after 29-09-2024 are searchable, for now :)"}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
            <ExperienceTimeline experience={modalData} isOpen={showExperienceModal} handleState={() => {
                setExperienceModal(!showExperienceModal);
                setModalData(null);
            }} />
        </>
    );
}
